export default [
  {
    title: 'admin.menu.system',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'admin.menu.file-manager',
        route: 'file-manager',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'admin.menu.settings',
        route: 'settings',
        action: 'index',
        resource: 'settings',
      },
      {
        title: 'admin.menu.languages',
        route: 'languages',
        action: 'index',
        resource: 'languages',
      },
      {
        title: 'admin.menu.translations',
        route: 'translations',
        action: 'index',
        resource: 'translations',
      },
      {
        title: 'admin.menu.currencies',
        route: 'currencies-index',
        action: 'index',
        resource: 'currencies',
      },
      {
        title: 'admin.menu.roles',
        route: 'roles-index',
        action: 'index',
        resource: 'roles',
      },
      {
        title: 'admin.menu.notification_templates',
        route: 'notification_templates-index',
        action: 'index',
        resource: 'notifications',
      },
    ],
  },
]
