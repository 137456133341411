export default [
  {
    title: 'admin.menu.users',
    icon: 'UserIcon',
    route: 'users-index',
    action: 'index',
    resource: 'users',
  },
  {
    title: 'admin.menu.transactions',
    icon: 'RepeatIcon',
    route: 'transactions-index',
    action: 'index',
    resource: 'transactions',
  },
]
